<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core';
import { Background } from '@ui/styles';
import { Phone, Envelope } from "@ui/icons/regular";
import { getCompanyImages, useCompanyStore } from '@sub/stores/company';
import ServiceListCard from "@sub/components/ServiceListCard.vue";
import { getCategoryLocale } from '@utils/categoryUtils';
import { joinPageTitle, usePageTitle } from '@sub/utils/usePageSettings';

const store = useCompanyStore();
const { company, companyInfo, services } = storeToRefs(store);

const i18n = useI18n();
usePageTitle(computed(() => joinPageTitle(company.value.clientName, i18n.t('page.services.title'))));

const { status } = useAsyncData(async() => {
  const companyId = await store.getCompanyIdByAlias();
  if (!companyId) return;
  return Promise.all([store.fetchCompany(companyId), store.fetchServices(companyId)]);
});

const showRules = ref(false);

const selectedAddresses = ref<number[]>([]);

const filteredServices = computed(() => services.value.main.filter(s => {
  return selectedAddresses.value.length ? (s.addresses || []).some(({id}) => selectedAddresses.value.includes(+id)) : true
}));

const addresses = computed(() => company.value.addresses);
const companyImages = computed(() => getCompanyImages(company.value));

const showViewer = ref(false);
const activeImages = ref([]);

function onOpenViewer(images) {
  activeImages.value = images;
  showViewer.value = true;
}

const seoImageUrl = computed(() => companyImages.value[0] || company.value.logoUrl);

useSeoMeta({
  description: company.value.clientDescription,
  ogTitle: company.value.clientName,
  ogDescription: company.value.clientDescription,
  ogImage: seoImageUrl,
  twitterTitle:company.value.clientName,
  twitterDescription: company.value.clientDescription,
  twitterImage: seoImageUrl,
  twitterCard: 'summary',
});

definePageMeta({
  keepalive: true,
});

const isMobileScreen = useMediaQuery('(max-width: 799px)')
</script>

<template lang="pug">
template(v-if="company && Object.keys(company).length")
  .company-info.overflow-hidden
    .company-text.w-100p.flex.col-24(v-if="company && Object.keys(company).length")
      .flex-unset-center.row-16
        UiImage.size-56(:src="company.logoUrl")
        .flex.col-0
          .title.typ-20-28-600 {{ company.clientName }}
          .category.typ-13-17-300(v-if="company.meta?.category") {{ $t(getCategoryLocale(company.meta?.category)) }}
      UiImageSlider.p-8-0(:src="companyImages" :show="isMobileScreen")
      .flex.col-16(v-if="company.meta && (company.meta.phone || company.meta.email)")
        .flex.row-12(v-if="company.meta.phone")
          UiIconsIcon.icon.size-16(:name="Phone")
          a.title.typ-13-17-400(:href="`tel:${company.meta.phone}`") {{ company.meta.phone }}
        .flex.row-12(v-if="company.meta.email")
          UiIconsIcon.icon.size-16(:name="Envelope")
          a.title.typ-13-17-400(:href="`mailto:${company.meta.email}`") {{ company.meta.email }}
        span.link.typ-14-17-400(v-if="company.rules" @click="showRules = true") {{ $t('block.term-of-service.title') }}
        span.description.typ-13-17-400(v-if="company.clientDescription") {{ company.clientDescription }}
    UiImage.image.flex-shrink-0.desktop(v-if="companyImages.length" :src="companyImages[0]" :zoom="true" @click="onOpenViewer(companyImages)")

  .services
    .p-b-16(v-if="false && addresses.length > 1")
      UiInputSelect(
        v-model="selectedAddresses"
        labelProp="country"
        placeholder="block.address-select.button.text.default"
        :background="Background.Outline"
        :options="addresses"
        :multiple="true"
      )
    .service(v-for="s of filteredServices")
      ServiceListCard(:service="s" :showServiceDuration="companyInfo.settings.showServiceDuration" :defaultImage="company.logoUrl" @open:images="onOpenViewer")

  UiLoaderCircleLoader.m-auto(v-if="status === 'pending'" title="page.services.loader")

UiSheetBottom(v-model:show="showRules" label="block.term-of-service.title")
  template(#content)
    .rules.m-0.typ-13-17-400 {{ company.rules }}

UiImageViewer(v-model:show="showViewer" :src="activeImages")
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/colors";

.company-info {
  background: #FCFCFC;
  border-bottom: 1px solid #EDEDF2;
  border-radius: 24px 24px 0 0;
  --uno: flex-between-start p-16-16-20 col-16;

  .image {
    height: calc(100dvw - 32px);
    width: calc(100dvw - 32px);
  }

  @screen sm {
    --uno: flex-between-start p-20-24;
    flex-flow: unset;

    .image {
      --uno: size-216;
    }
  }
}

.desktop {
  display: none;

  @screen md {
    display: unset;
  }
}

.company-text {
  display: contents;
  @screen sm {
    display: flex;
  }
}

.service:not(:last-child) {
  border-bottom: 1px solid #E5E5E9;
}

.title {
  color: #1F1F1F;
  text-decoration: none;
}

.category {
  color: #5B646D;
}

.description {
  color: #5B646D;
  white-space: pre-wrap;
}

.rules {
  white-space: pre-line;
  color: #5b646d;
}

.link {
  cursor: pointer;
  color: #3870FF;
}

.icon {
  color: #ABB1B6;
}

.services {
  --uno: p-16;
  @screen md {
    --uno: p-16-24;
  }
}
</style>