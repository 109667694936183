<script setup lang="ts">
import type { IService } from "@types";
import { getMinPriceByService } from '../utils/CuePriceLib2/ServicePriceLib';
import { usePriceFormatter } from '../utils/prices';
import { getServiceImages } from '../utils/images';

const props = withDefaults(defineProps<{
  service: IService;
  defaultImage: string;
  showServiceDuration: boolean;
}>(), {
  service: () => ({} as any),
  defaultImage: '',
  showServiceDuration: true,
})

const serviceUrl = computed(() => `/services/${props.service.id}`);
const images = computed(() => getServiceImages(props.service));

const { formatPrice } = usePriceFormatter();
</script>

<template lang="pug">
.flex.col-12.p-16-0
  .flex.row-16
    UiImage.size-100.flex-shrink-0(:src="images[0]" :zoom="!!images[0]" :showZoom="false" :border="false" :placeholder="defaultImage" @click="images[0] && $emit('open:images', images)")
    .card-info.overflow-hidden
      .card-title.flex.overflow-hidden
        span.title.typ-14-18-400 {{ service.title }}
        span.description.typ-13-17-400 {{ service.addresses?.[0]?.country }}
        NuxtLink.link.typ-14-18-400.desktop(:to="serviceUrl" :aria-label="`${service.title}: ${$t('block.service.row.info.link.text')}`") {{ $t('block.service.row.info.link.text') }}
      .card-price.col-8
        .flex.row-8.white-space-nowrap
          template(v-if="showServiceDuration")
            ServiceTimeDuration.time.typ-14-18-300(:service="service")
            .dot •
          .price.typ-14-18-400
            | {{ $t('word.from.money.base') }}
            | {{ formatPrice(getMinPriceByService(service)) }}
        .card-actions.flex.row-16.p-t-12.desktop
          UiButton(hierarchy="secondary" size="small" label="block.service.info.page.button.booking.text.base" :to="`/booking/${service.id}`")
  .card-actions.flex.row-16.mobile
    UiButton(hierarchy="secondary" kind="outline" size="small" label="block.service.row.button.list.info.text" :to="serviceUrl")
    UiButton(hierarchy="secondary" size="small" label="block.service.info.page.button.booking.text.base" :to="`/booking/${service.id}`")
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/colors";

.title {
  color: #1F1F1F;
}

.description,
.time {
  color: #5B646D;
}

.description {
  --uno: text-ellipsis;
  white-space: unset;
}

.link {
  cursor: pointer;
  color: #3870FF;
  text-decoration: none;
}

.dot {
  color: #C3C9CF;
}

.price {
  color: #1F1F1F;
}

.card-title {
  --uno: col-8 flex-shrink-0;

  @screen md {
    --uno: col-12;
    flex-shrink: unset;
  }
}

.card-info {
  --uno: flex col-8 w-100p;

  @screen md {
    display: contents;
  }
}

.card-price {
  --uno: flex-between-start h-100p;

  @screen md {
    --uno: m-l-auto flex-between-flex-end;
    height: unset;
  }
}

.desktop {
  display: none;

  @screen md {
    display: unset;
  }
}

.mobile {
  @screen md {
    display: none;
  }
}

.card-actions {
  --uno: m-l-auto;
  place-content: end;

  @screen md {
    --uno: m-l-0;
  }
}
</style>